import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoint } from "../styles"
import Tag from "./tag"

const Photo = ({ image, site, setTag, tag }) => {
  return (
    <Wrapper>
      <a href={site && site.src}>
        <div className="boxFluid">
          <Img
            fluid={image && image.childImageSharp.fluid}
            alt={site && site.name}
          />
          {/* <div className="overlay">{site.name}</div> */}
        </div>
      </a>
      <div className="desc">
        <h3>
          {/* <a href={site && site.src}>{site && site.name}</a> */}
          <a>{site && site.name}</a>
        </h3>
        <p>{site && site.desc}</p>
        <div class="github">
          <a href={site && site.github && site.github}></a>
        </div>
        <div className="tags">
          {site &&
            site.tags &&
            site.tags.map(currentTag => (
              <Tag
                onClick={() => setTag(currentTag)}
                isActive={currentTag === tag ? true : false}
              >
                {currentTag}
              </Tag>
            ))}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background-color: #13141a;
  border-radius: 10px;
  padding: 1rem;
  .desc {
    padding: 0 1rem;

    @media ${breakpoint.sm} {
      padding: 0;
    }

    h3 {
      margin: 1rem 0 0 0;
    }
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        /* text-decoration: underline; */
      }
    }
    .tags {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
  }

  .boxFluid {
    position: relative;
    transform: scale(1, 1);
    width: 100%;
    transition: all 0.2s ease-in-out;

    img {
      background-color: white;
      border-radius: 10px;
    }

    &:hover {
      /* color: red; */
      display: pointer;
      /* box-shadow: 5px -5px white; */
      transition: all 0.2s ease-in-out;
      transform: scale(1.05, 1.05);
      /* img {
        filter: blur(8px);
      } */
    }

    .overlay {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(40, 42, 43, 0.7);
      filter: opacity(0);
      font-size: 1.5rem;
      line-height: 1rem;
      text-align: center;
    }
  }
`

export default Photo
