import React from "react"
import styled from "styled-components"

const Tag = props => {
  return <Wrapper {...props}>{props.children}</Wrapper>
}

export default Tag

const Wrapper = styled.div`
  display: inline-block;
  color: ${props => (props.isActive ? "inherit" : "#ea00b7")};
  border: 1px solid #ea00b7;
  border-radius: 15px;
  padding: 0.3rem 0.5rem;
  margin: 0.2rem 0.5rem 0.2rem 0;
  background-color: ${props => (props.isActive ? "#ea00b7" : "inherit")};
  /* transition: all 0.2s ease-in-out; */
  &:hover {
    cursor: pointer;
    /* text-decoration: underline; */
    background-color: ${props => (props.isActive ? "#ea00b7" : "#752d64")};
  }
`
