import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { sites, games } from "../constants/sites"
import Photo from "./photo"

import styled from "styled-components"
import { breakpoint } from "../styles"

const PhotoGrid = () => {
  const data = useStaticQuery(graphql`
    query GetImages {
      commerceImage: file(relativePath: { eq: "home/commerce.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      wuphfImage: file(relativePath: { eq: "home/wuphf-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pizzayouImage: file(
        relativePath: { eq: "home/pizzayou-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      overthrowImage: file(
        relativePath: { eq: "home/overthrow-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bakeryImage: file(relativePath: { eq: "home/bakery-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      chatImage: file(relativePath: { eq: "home/chatcord-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      wordGuessImage: file(
        relativePath: { eq: "home/wordguess-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      reggieCubeImage: file(
        relativePath: { eq: "home/reggiecube-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      kingsbookImage: file(
        relativePath: { eq: "home/kingsbook-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      spaceImage: file(
        relativePath: { eq: "home/spaceshooter_screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const [tag, setTag] = useState("")

  const filteredSites =
    tag !== "" ? sites.filter(site => site.tags.includes(tag)) : sites
  const filteredGames =
    tag !== "" ? games.filter(site => site.tags.includes(tag)) : games

  const generateImage = name => {
    switch (name) {
      case "Commerce Bank Cubicle Reservations":
        return data.commerceImage
      case "WUPHF":
        return data.wuphfImage
      case "Pizza You":
        return data.pizzayouImage
      case "Overthrow":
        return data.overthrowImage
      case "Charles' Bakery":
        return data.bakeryImage
      case "ChatCord":
        return data.chatImage
      case "Word Guess Game":
        return data.wordGuessImage
      case "Reggie Cube":
        return data.reggieCubeImage
      case "The King's Book":
        return data.kingsbookImage
      case "Space Shooter 2D":
        return data.spaceImage
      default:
        return ""
    }
  }

  const onTagClick = tagClicked => {
    tagClicked === tag ? setTag("") : setTag(tagClicked)
  }

  return (
    <Wrapper>
      {filteredSites.length > 0 && (
        <h1 className="title">PORTFOLIO - WEBSITES</h1>
      )}
      <div className="gridContainer">
        {filteredSites.map(site => {
          const image = generateImage(site.name)

          return (
            <Photo site={site} image={image} setTag={onTagClick} tag={tag} />
          )
        })}
      </div>
      <div id="top-margin">
        {filteredGames.length > 0 && (
          <h1 className="title">PORTFOLIO - GAMES</h1>
        )}
        <div className="gridContainer">
          {filteredGames.map(site => {
            const image = generateImage(site.name)

            return (
              <Photo site={site} image={image} setTag={onTagClick} tag={tag} />
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .title {
    margin-left: 1rem;
    @media ${breakpoint.sm} {
      margin-left: 0rem;
    }
  }
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.25rem;
    justify-items: center;
    /* align-items: center; */
    /* padding: 0.5rem 0 1rem 0; */
    /* background-color: yellow; */

    @media (min-width: 1100px) {
      grid-template-columns: 1fr 1fr;
    }

    @media ${breakpoint.xl} {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  #top-margin {
    margin-top: 4rem;
  }
`

export default PhotoGrid
