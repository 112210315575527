import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PhotoGrid from "../components/photogrid"

const IndexPage = () => (
  <Layout noContainer>
    <SEO title="Home" />
    <PhotoGrid />
  </Layout>
)

export default IndexPage
